import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"
import LatestArticles from "components/blocks/LatestArticles"


const Thanks = () => {

  // using static header image, load here
  const data = useStaticQuery(
    graphql`
    query {
      heroImage: file(relativePath: { eq: "backgrounds/renewables-sunset.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `
  )


  return (
    <Layout>
      <Seo title="Page" />

      {/* Title banner */}
      <ImgBackground image={data.heroImage}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex">
          <TitleBox
            title="Thank you for reaching out"
            caption="Contact Us"
          >
            <p className="text-2xl text-white font-normal">Your contact request has been sent to our team in London :)</p>
          </TitleBox>
        </div>
      </ImgBackground>


      {/* Latest articles */}
      <section className="border-t border-gray-50 py-16 pt-32">
        <LatestArticles />
      </section>


    </Layout>
  )
}

export default Thanks
